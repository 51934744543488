<template>

  <div class="search-header">
    <a-form layout="inline"
            :model="searchForm">
      <a-form-item>
        <a-input-search v-model:value="searchForm.nameOrPhone"
                        placeholder="请输入客户姓名 / 手机号"
                        style="width: 26%; min-width: 320px"
                        @keydown.enter="onSearchSubmit"
                        v-debounce="onSearchSubmit" />
      </a-form-item>
    </a-form>
    <router-link :to="{name: 'customerImport_create'}">
      <a-button type="primary"
                centered
                style="width: 146px;height: 36px;border-radius:4px">
        <PlusOutlined />导入客户
      </a-button>
    </router-link>

  </div>

  <a-tabs type="card"
          v-model:activeKey="searchForm.status"
          @change="findCustomerStateList">
    <a-tab-pane key=""
                tab="全部"></a-tab-pane>
    <a-tab-pane key="notAdd"
                tab="待添加"></a-tab-pane>
    <a-tab-pane key="added"
                tab="已添加"></a-tab-pane>
  </a-tabs>

  <a-table :columns="customerColumns"
           :data-source="customer"
           row-key="allotId"
           :pagination="pagination"
           :scroll="{ x: '100%', y: 'calc(100vh - 378px)' }"
           @change="onPaginationChange"
           :loading="tableLoading">

    <template #corpTag="{ record }">
      <a-tag v-for="tag in record.tags"
             :key="tag.workWechatThirdAppTagId">{{ tag.name }}</a-tag>
    </template>

    <template #staffWorkWechatThirdAppUserId="{ text }">
      <div v-is="'ww-open-data'"
           class="ww-open-data"
           type="userName"
           :openid="text"></div>
    </template>

    <template #status="{text}">
      {{text === 'notAdd' ? "待添加" : "已添加"}}
    </template>

    <template #importTime="{ text }">{{$f.datetime(text).slice(0,16)}}</template>

    <template #createdTime="{ text }">{{$f.datetime(text).slice(0,16)}}</template>

    <template #action="{ record }">
      <a-space :size="16">
        <span class="color-primary cursor-pointer"
              v-if="record.status === 'notAdd'"
              @click='openRemindModal(record)'>提醒</span>
        <router-link v-else-if="record.status === 'added'"
                     :to="{path:'/customer/customerDetail',query:{ customerId: record.customerId, id: record.relationId}}">
          详情
        </router-link>
        <span class="color-primary cursor-pointer"
              v-if="record.status === 'notAdd' && searchForm.status==='notAdd'"
              @click='openAssignDialog(record)'>分配</span>

        <span class="gray cursor-pointer"
              @click='openDeleteModal(record)'>删除</span>
      </a-space>
    </template>
  </a-table>

  <a-modal class="remind-modal"
           v-model:visible="remindModalVisible"
           title="提醒"
           width="400px"
           @ok="remindStaff">
    <span class="gray">将发送消息提醒「
      <div v-is="'ww-open-data'"
           class="ww-open-data"
           type="userName"
           :openid="remindStaffInfo.staffWorkWechatThirdAppUserId"></div>
      」添加客户好友，确认发送？
    </span>
  </a-modal>

  <a-modal v-model:visible="deleteModalVisible"
           title="删除客户"
           width="400px"
           @ok="deleteCustomer">
    <span class="gray">删除后无法恢复，确认删除？</span>
  </a-modal>

  <a-modal class="assign-staff-modal"
           v-model:visible="assignModalVisible"
           title="分配客户"
           width="600px"
           @ok="allotStaff"
           @cancel="closeAssignModal">
    <div class="customer-info">
      <a-row class="mb16">
        <a-col flex="64px">
          姓名
        </a-col>
        <a-col :flex="1">
          {{assignForm.customerName}}
        </a-col>
      </a-row>
      <a-row>
        <a-col flex="64px">
          手机号
        </a-col>
        <a-col :flex="1">
          {{assignForm.phone}}
        </a-col>
      </a-row>
    </div>
    <a-form :model="assignForm">
      <a-form-item label="分配员工"
                   help="* 上传的客户将平均分配给选择的员工，分配完成后员工会收到提醒。">

        <a-dropdown trigger="click"
                    overlayClassName="select-staff-dropdown"
                    placement="bottomLeft">
          <div class="ant-input-affix-wrapper  flex justify-between"
               style="max-width: 200px;">

            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="assignForm.staffName"></div>
          </div>

          <template #overlay>
            <select-staff @choose="chooseAllotStaff">
            </select-staff>
          </template>
        </a-dropdown>
      </a-form-item>
      <a-form-item label="客户标签"
                   help="* 添加成功的客户会被自动添加选择的标签。">
        <a-select v-model:value="assignForm.tagList"
                  :showSearch="false"
                  mode="multiple"
                  placeholder="请选择">
          <a-select-opt-group v-for="corpTagGroup in corpTagList"
                              :key="corpTagGroup.workWechatThirdAppTagGroupId"
                              :label="corpTagGroup.name">
            <a-select-option v-for="tag in corpTagGroup.corpTagList"
                             :key="tag.workWechatThirdAppTagId">
              {{tag.name}}
            </a-select-option>
          </a-select-opt-group>
        </a-select>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { Tabs, Space, message, Dropdown } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { apiBus } from "@/shared/service/api-client";

import SelectStaff from "@/components/SelectStaff";

import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import customerImportApi from "@/service/api/customerImport";
import CorpTagApi from "@/service/api/corpLag";
import { getWxAgentConfig } from "@/global";

export default defineComponent({
  name: "CustomerImportant",
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ASpace: Space,
    ADropdown: Dropdown,

    SelectStaff,
  },
  setup() {
    const searchForm = reactive({
      status: "",
      nameOrPhone: "",
      isDeleted: 0,
    });

    const customerColumns = [
      {
        title: "姓名",
        dataIndex: "name",
        width: "150px",
        fixed: "left",
      },

      {
        title: "手机号",
        dataIndex: "phone",
        width: "150px",
      },

      {
        title: "企业标签",
        dataIndex: "tags",
        slots: { customRender: "corpTag" },
      },
      {
        title: "分配员工",
        dataIndex: "staffWorkWechatThirdAppUserId",
        width: "130px",
        slots: { customRender: "staffWorkWechatThirdAppUserId" },
      },
      {
        title: "分配次数",
        dataIndex: "allotCount",
        width: "130px",
      },
      {
        title: "状态",
        dataIndex: "status",
        width: "130px",
        slots: { customRender: "status" },
      },
      {
        title: "导入时间",
        dataIndex: "createdTime",
        slots: { customRender: "importTime" },
      },
      {
        title: "添加时间",
        dataIndex: "addFriendTime",
        slots: { customRender: "createdTime" },
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
        fixed: "right",
      },
    ];
    const router = new useRouter();
    const route = new useRoute();
    const { status } = route.query;
    if (status) {
      searchForm.status = status;
    }

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, customerImportApi.search);

    function findCustomerStateList(activeKey) {
      searchForm.status = activeKey;
      searchForm.page = 0;
      onSearchSubmit();
    }

    const remindStaffInfo = ref({});
    const remindModalVisible = ref(false);
    function openRemindModal(record) {
      remindStaffInfo.value = record;
      remindModalVisible.value = true;
    }
    async function remindStaff() {
      await customerImportApi.remind({
        allotId: remindStaffInfo.value.allotId,
      });
      remindModalVisible.value = false;
    }

    const deleteId = ref(undefined);
    const deleteModalVisible = ref(false);
    function openDeleteModal(record) {
      deleteId.value = record.allotId;
      deleteModalVisible.value = true;
    }
    async function deleteCustomer() {
      await customerImportApi.delete({ id: deleteId.value });
      message.success("删除成功");
      fetchPaginationData();
      deleteModalVisible.value = false;
    }

    const corpTagList = ref();
    async function getTagList() {
      corpTagList.value = await CorpTagApi.getList();
    }

    const assignForm = reactive({});
    const assignModalVisible = ref(false);
    function openAssignDialog(record) {
      assignForm.allotId = record.allotId;
      assignForm.staffName = record.staffName;
      assignForm.staffId = record.staffId;
      assignForm.customerName = record.name;
      assignForm.phone = record.phone;

      assignForm.tagList = [];
      _.forEach(record.tags, (tag) => {
        assignForm.tagList.push(tag.workWechatThirdAppTagId);
      });
      assignModalVisible.value = true;
    }
    function chooseAllotStaff(staff) {
      const { staffName, id: staffId } = staff;
      _.assign(assignForm, { staffName, staffId });
    }
    async function allotStaff() {
      assignForm.corpTagIds = assignForm.tagList.join(",");
      await customerImportApi.allot(assignForm);
      message.success("分配成功");
      fetchPaginationData();
      assignModalVisible.value = false;
    }
    function closeAssignModal() {
      apiBus.emit("closeAssignModal");
    }

    getWxAgentConfig();
    getTagList();

    onMounted(() => {});
    return {
      searchForm,
      customerColumns,

      customer: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,

      tableLoading,

      findCustomerStateList,

      remindModalVisible,
      remindStaffInfo,
      openRemindModal,
      remindStaff,

      deleteModalVisible,
      openDeleteModal,
      deleteCustomer,

      corpTagList,

      assignForm,
      assignModalVisible,
      openAssignDialog,
      chooseAllotStaff,
      allotStaff,
      closeAssignModal,
    };
  },
});
</script>
<style lang='less' scoped>
.ant-select-multiple {
  width: 200px;
}
:deep(.ant-form-explain) {
  margin-top: 12px;
  margin-bottom: 16px;
}
.assign-staff-modal {
  .customer-info {
    padding: 16px;
    margin-bottom: 24px;
    .text-14();
    color: @gray-darker;
    background-color: @bg-normal;
  }
}

:deep(.ant-input-affix-wrapper) {
  &::before {
    display: none;
  }
}
</style>