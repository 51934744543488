import {apiClient as client} from '@/shared/service/api-client';

export default {
    async search(params) {
        return await client.get("/customerAllot/search", {params: params});
    },
    async delete(params) {
        return await client.post("/customerAllot/delete", params);
    },
    async allot(params) {
        return await client.post("/customerAllot/allot", params);
    },
    async remind(params) {
        return await client.get("/customerAllot/remind", {params: params});
    },
    async checkImport(params) {
        return await client.post("/customerAllot/checkImport", params);
    },
    async import(params) {
        return await client.post("/customerAllot/import", params);
    },
}